(function($) {

    // Load Events
    $( document ).ready( function() {


        /*
         * Nano Bar
         */
        var nanoOptions = {
            bg: '#FFF',
            // leave target blank for global nanobar
            target: '',
            // id for new nanobar
            id: 'page-loader'
        };
        var nanobar = new Nanobar( nanoOptions );
        // move bar
        nanobar.go( 30 ); // size bar 30%
        // Finish progress bar
        nanobar.go(100);



        /*
         * Sticky Header on scroll
         */
        var header = $('#js-header');

        // window.addEventListener('scroll', function(e){
        //     var distanceY = window.pageYOffset || document.documentElement.scrollTop,
        //         stickOn = $('#js-banner').outerHeight();

        //     if (distanceY > stickOn) {
        //         header.addClass('site-head--sticky  animated  slideInDown');
        //         header.removeClass('site-head--inverted');
        //     } else {
        //         if (header.hasClass('site-head--sticky')) {
        //             header.removeClass('site-head--sticky  animated  slideInDown');
        //         }
        //     }
        // });


        /*
         * Mobile Menu
         */

        $('.js-menuToggle').on('click', function(e){
            e.preventDefault();
            $('.js-menuToggle').toggleClass('burger--open');
            $('.nav-primary__menu').toggleClass('nav-primary__menu--open  animated  fadeIn');
            $('#js-header').toggleClass('site-head--open');
        });

        function closeMenu() {
            $('.js-menuToggle').removeClass('burger--open');
            $('#js-header').removeClass('site-head--open');
            $('.nav-primary__menu').removeClass('nav-primary__menu--open  animated  fadeIn');
        }

        $(window).resize(function(){
            closeMenu();
        });



        /*
         * Page Slider
         * @desc Settings via data-attributes
         */

        $('.js-slick').slick();



        /*
         * Smooth Scroll
         * https://css-tricks.com/snippets/jquery/smooth-scrolling/
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 113
                    }, 1000);
                    return false;
                }
            }
        });


        /*
         * Toggles
         */
        $('.js-do-toggle').on('click', function(e) {
            e.preventDefault();
            // reset
            $('.js-toggle-group').addClass('hidden');
            $('.js-toggle-item-plus').removeClass('animated  rotateOut');
            // hide group item
            var itemID = $(this).attr('data-toggle');
            $('#'+itemID).removeClass('hidden');
            $(this).find('.js-toggle-item-plus').addClass('animated  rotateOut');
        });


        /*
         * Equal height panels
         */
        function equalHeightPanels () {
            var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;

            $('.js-panel').each(function() {

                $el = $(this);
                topPosition = $el.position().top;

                if (currentRowStart != topPosition) {

                    // we just came to a new row.  Set all the heights on the completed row
                    for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                        rowDivs[currentDiv].height(currentTallest);
                    }

                    // set the variables for the new row
                    rowDivs.length = 0; // empty the array
                    currentRowStart = topPosition;
                    currentTallest = $el.height();
                    rowDivs.push($el);

                } else {

                    // another div on the current row.  Add it to the list and check if it's taller
                    rowDivs.push($el);
                    currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);

                }

                // do the last row
                for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }

            });
        }
        equalHeightPanels();
        $(window).resize(function(){
            equalHeightPanels();
        });
    });

})(jQuery); // Fully reference jQuery after this point.
